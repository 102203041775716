// Chakra imports
import { Button, Flex, Input, useColorModeValue } from "@chakra-ui/react";
// Assets
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import { UploadFile } from "services/upload";

function Dropzone(props) {
  const { content, onImageSelect, limiteSize, accept, multiple, noStyle, ...rest } = props;

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (limiteSize) {
        const limiteSizeInBytes = limiteSize * 1024 * 1024;
        const oversizedFiles = acceptedFiles.filter(file => file.size > limiteSizeInBytes);

        if (oversizedFiles.length > 0) {
          toast(`Erro ao carregar arquivos. Um ou mais arquivos ultrapassam o tamanho máximo permitido de ${limiteSize}MB.`, {
            type: 'warning'
          });
          return;
        }
      }

      const uploadPromises = acceptedFiles.map(async (file) => {
        const responseLogo = await UploadFile(file);
        return responseLogo.result || '';
      });

      try {
        const results = await Promise.all(uploadPromises);
        onImageSelect(results);
      } catch (error) {
        console.error('Erro ao carregar os arquivos:', error);
      }
    },
    [onImageSelect, limiteSize]
  );

  const { getRootProps, getInputProps } = useDropzone({ accept, onDrop, multiple });
  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  if (noStyle) {
    return (
        <Flex
            flexDirection="column"
            {...getRootProps({ className: "dropzone" })}
            {...rest}
            cursor="pointer"
        >
            <Input variant='main' {...getInputProps()} />
            <Button variant='no-effects'>{content}</Button>
        </Flex>
    );
  }

  return (
    <Flex
      align='center'
      justify='center'
      bg={bg}
      border='1px dashed'
      borderColor={borderColor}
      borderRadius='16px'
      w='100%'
      h='max-content'
      minH='100%'
      cursor='pointer'
      {...getRootProps({ className: "dropzone" })}
      {...rest}>
      <ToastContainer />
      <Input variant='main' {...getInputProps()} />
      <Button variant='no-effects'>{content}</Button>
    </Flex>
  );
}

export default Dropzone;
