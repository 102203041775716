export const columnsDataColumns = [
    {
        Header: "TÍTULO",
        accessor: "title",
    },
    {
        Header: "CRIADO EM",
        accessor: "created_at",
    },
    {
        Header: "AÇÕES",
        accessor: "actions",
    },
];
