import {
    Box,
} from "@chakra-ui/react";
import React from "react";
import Card from "components/card/Card";
import Pricing from "./components/Pricing";

export default function Users() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Pricing />
      </Card>
    </Box>
  );
}
