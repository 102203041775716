import axios from 'axios';
import { api } from './api';

export async function GenerateNewParagraph(oldMessage) {
    try {
        const body = {
            "model": "gpt-3.5-turbo-16k",
            "messages": [
                {
                    "role": "system",
                    "content": `
                        A partir de agora você deve se comportar com um assessoria jurídico experiente no contexto jurídico do direito brasileiro.
                        Você é um advogado e deve reformular o parágrafo abaixo, adicionando mais robustez e informações jurídicas baseado
                        nas informações existentes nele para que tudo fica condizente. O objetivo é apenas alterar o conteúdo da mensagem
                        para que ela fique diferente do original, trazendo uma nova abordagem para o parágrafo. Parágrafo que deve ser reformulado: ${oldMessage}
                    `
                },
            ],
            "temperature": 1,
            "max_tokens": 15000,
            "top_p": 1,
            "frequency_penalty": 2,
            "presence_penalty": 2
        };
        const response = await axios.post('https://api.openai.com/v1/chat/completions', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer 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`,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function GenerateLegalTopics(form, topic) {
    try {
        // Segue abaixo informações importantes sobre a petição inicial:
        // Nome do cliente: ${form.nome}
        // Valor da causa: ${form.valor}
        // Tipo da ação: ${form.tipo}
        // Juízo competente: ${form.juizo}
        // Provas do processo: ${form.provas}
        // Fundamento jurídico: ${form.fundamentos}
        // Descrição dos fatos: ${form.description}
        const body = {
            "model": "gpt-3.5-turbo-16k",
            "messages": [
                {
                    "role": "system",
                    "content": `
                        A partir de agora você deve se comportar com um assessoria jurídico experiente no contexto jurídico do direito brasileiro.
                        Você é um advogado e vai ajuizar ação em favor de seu cliente, chamado ${form.nome}. A partir das informações abaixo,
                        redija 5 parágrafos robustos e o maior possível sobre o tópico ${topic} em nenhum momento dessa resposta você deve inserir uma definição
                        sobre o que é e como funciona o tópico ${topic},
                        lembre-se de inserir o título do tópico
                        informado anteriormente em negrito e uppercase. Use linguagem jurídica, em tom técnico, lembre-se também de retornar todo o conteúdo em formato HTML
                        contendo apenas o body, para os títulos use h2 com negrito, para os parágrafos use a tag p com br para espaçamentos. Responda
                        em português brasileiro seguindo as normas gramaticais aceitas. Lembre-se de não inserir o valor da causa nas respostas.
                        Dados adicionais do processo:
                        Nome do cliente: ${form.nome}
                        Tipo da ação: ${form.tipo}
                        Juízo competente: ${form.juizo}
                    `
                },
            ],
            "temperature": 1,
            "max_tokens": 15000,
            "top_p": 1,
            "frequency_penalty": 0,
            "presence_penalty": 0
        };
        const response = await axios.post('https://api.openai.com/v1/chat/completions', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer 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`,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function GenerateRequest(form, topic) {
    try {
        const body = {
            "model": "gpt-3.5-turbo-16k",
            "messages": [
                {
                    "role": "system",
                    "content": `
                        A partir de agora você deve se comportar com um assessoria jurídico experiente no contexto jurídico do direito brasileiro.
                        Você é um advogado e vai ajuizar ação em favor de seu cliente, chamado ${form.nome}. Redija um pedido de até 3 linhas sobre o tópico ${topic} que será
                        usado futuramente na seção DOS PEDIDOS de uma petição inicial, não insira o título do tópico. Use linguagem jurídica, em tom técnico. Responda
                        em português brasileiro seguindo as normas gramaticais aceitas.
                    `
                },
            ],
            "temperature": 1,
            "max_tokens": 15000,
            "top_p": 1,
            "frequency_penalty": 0,
            "presence_penalty": 0
        };
        const response = await axios.post('https://api.openai.com/v1/chat/completions', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer 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`,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function GenerateFatos(form) {
    try {
        const body = {
            "model": "gpt-3.5-turbo-16k",
            "messages": [
                {
                    "role": "system",
                    "content": `
                        A partir de agora você deve se comportar com um assessoria jurídico experiente no contexto jurídico do direito brasileiro.
                        Você é um advogado e vai ajuizar ação em favor de seu cliente, chamado ${form.nome}. 
                        Lembre-se de inserir o título do tópico informado anteriormente DOS FATOS. Use linguagem jurídica, em tom técnico, lembre-se também de retornar
                        todo o conteúdo em formato HTML contendo apenas o body, para os títulos use h2 com negrito, para os parágrafos use a tag p com br para espaçamentos.
                        Responda em português brasileiro seguindo as normas gramaticais aceitas. Lembre-se de não inserir o valor da causa nas respostas.
                        Dados adicionais do processo:
                        Nome do cliente: ${form.nome}
                        Tipo da ação: ${form.tipo}
                        Juízo competente: ${form.juizo}
                        A partir das informações anteriores,
                        redija 5 parágrafos robustos e o maior possível apresentando a seção DOS FATOS de uma petição inicial baseado nessa descrição:
                        ${form.description}
                    `
                },
            ],
            "temperature": 1,
            "max_tokens": 15000,
            "top_p": 1,
            "frequency_penalty": 0,
            "presence_penalty": 0
        };
        const response = await axios.post('https://api.openai.com/v1/chat/completions', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer 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`,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function GenerateJuris(inteiroTeor, token) {
    try {
        let baseURL = `/jurisprudencia?inteiroTeor=${inteiroTeor}`;
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function GeneratePetitionInitial(form, type) {
    try {
        const infoParagraph = type === 'Dos fatos' ? `
            A partir das informações abaixo,
            redija a seção "Dos fatos" contendo 5 parágrafos bem construídos e robusto para uma petição inicial do tipo ${form.tipo}.
        ` : `
                A partir das informações abaixo, redija a seção "Do direito" contendo 5 temas como "Da Responsabilidade Civil por Ato Ilícito"
                que seja relacionado ao tipo ${form.tipo} contendo 5 parágrafos bem construídos e robusto para uma petição inicial. 
        `
        const body = {
            "model": "gpt-3.5-turbo-16k",
            "messages": [
                {
                    "role": "system",
                    "content": `
                    Você é um advogado e vai ajuizar ação em favor de seu cliente, chamado ${form.nome}. ${infoParagraph}
                    Use linguagem jurídica, em tom técnico, lembre-se também de retornar todo o conteúdo em formato HTML e adicionar distancia entre os parágrafos, além de negritar os títulos. Segue abaixo informações importantes para a formulação dos parágrafos:
                    Nome do cliente: ${form.nome}
                    Valor da causa: ${form.valor}
                    Tipo da ação: ${form.tipo}
                    Juízo competente: ${form.juizo}
                    Provas do processo: ${form.provas}
                    Fundamento jurídico: ${form.fundamentos}
                    Descrição dos fatos: ${form.description}
                    `
                },
            ],
            "temperature": 1,
            "max_tokens": 15000,
            "top_p": 1,
            "frequency_penalty": 0,
            "presence_penalty": 0
        };
        const response = await axios.post('https://api.openai.com/v1/chat/completions', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer 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`,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function GerarRecursoOuContestacao(petitionInitial, type) {
    try {
        const body = {
            "model": "gpt-3.5-turbo-16k",
            "messages": [
                {
                    "role": "system",
                    "content": `
                        ${petitionInitial}
                        Acima contém todas as informações do processo, ela corresponde à uma petição inicial, Baseado nas informações acima, formule um ${type}
                        para o juiz. A resposta precisa ser em HTML com parágrafos isolados e títulos negritados. Se possível elaborar um ${type} com pelo menos 10
                        parágrafos robustos.
                    `
                },
            ],
            "temperature": 1,
            "max_tokens": 14000,
            "top_p": 1,
            "frequency_penalty": 0,
            "presence_penalty": 0
        };
        const response = await axios.post('https://api.openai.com/v1/chat/completions', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer 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`,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
