/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
import ColumnsTable from "./components/ColumnsTable";
import { Icon } from "@chakra-ui/react";
import { useAuthContext } from "contexts/SidebarContext";
import { columnsDataColumns } from "./variables/columnsData";
import { ListPetition } from "services/peticoes";
import { SiGoogledocs } from "react-icons/si";

export default function Users() {
    const { user } = useAuthContext();

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');
    const [tabCard, setTabCard] = useState(0);

    useEffect(() => {
        if (tabCard) {
            const timeOutId = setTimeout(() => {
                getList();
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [currentPage, searchTxt, tabCard]);

    useEffect(() => {
        setSearchTxt('');
        setCurrentPage(1);
        setTotalPages(1);
        setList([]);
    }, [tabCard]);

    const formatType = () => {
        if (tabCard === 1) {
            return 'inicial';
        }
        if (tabCard === 2) {
            return 'contestacao';
        }
        if (tabCard === 3) {
            return 'recurso';
        }
        if (tabCard === 4) {
            return 'contrato';
        }
        if (tabCard === 5) {
            return 'parecer';
        }
        if (tabCard === 6) {
            return 'replica';
        }
        if (tabCard === 7) {
            return 'trabalhistas';
        }
    };

    const getList = async () => {
        const type = formatType();
        const response = await ListPetition(user?.user?.id, type, null, searchTxt, currentPage, user?.token);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
    };

    const cards = [
        {
          id: 1,
          title: 'Petições iniciais',
          description: 'Inicie a criação de documentos legais essenciais com nosso fluxo intuitivo para Petições Iniciais. Este card leva você a uma listagem de petições já criadas, permitindo que você visualize, edite ou crie novas petições. Utilizando a tecnologia avançada do advtechpro.ai, preencha automaticamente os detalhes necessários para gerar um documento Word preciso e personalizado.',
        },
        {
          id: 7,
          title: 'Petições Trabalhistas',
          description: 'Inicie e gerencie Petições Trabalhistas de maneira fácil e eficiente. Este card leva você a uma listagem de petições trabalhistas já criadas, onde você pode visualizar, editar ou criar novos documentos. Com a ajuda do advtechpro.ai, preencha automaticamente os detalhes necessários para gerar um documento Word preciso e personalizado, adequado para o âmbito trabalhista.',
        },
        {
          id: 2,
          title: 'Contestações',
          description: 'Gerencie e crie Contestações com facilidade. Ao clicar neste card, você será redirecionado para uma lista de contestações existentes, onde pode visualizar e modificar documentos ou iniciar a criação de novos. Com a ajuda do advtechpro.ai, colete informações relevantes e produza um documento Word profissional e detalhado, pronto para ser utilizado.',
        },
        {
          id: 3,
          title: 'Recursos',
          description: 'Acesse e crie Recursos de maneira eficiente. Este card direciona você a uma listagem de recursos disponíveis, permitindo que você os visualize ou crie novos conforme necessário. Com a integração do advtechpro.ai, preencha automaticamente os dados necessários e gere um documento Word bem-estruturado e adaptado às suas necessidades específicas.',
        },
        {
          id: 4,
          title: 'Contrato',
          description: 'Simplifique a elaboração de Contratos com nosso fluxo especializado. Ao clicar neste card, você encontrará uma listagem de contratos existentes e a opção de criar novos. O advtechpro.ai auxiliará na coleta de informações e na redação do documento, resultando em um contrato Word completo e profissional, pronto para assinatura.',
        },
        {
          id: 5,
          title: 'Parecer',
          description: 'Facilite a criação de Pareceres Jurídicos detalhados. Este card leva você a uma listagem de pareceres já criados, permitindo a visualização, edição ou criação de novos documentos. Com o suporte do advtechpro.ai, preencha os detalhes necessários e produza um parecer jurídico em formato Word, com precisão e clareza.',
        },
        {
          id: 6,
          title: 'Réplica',
          description: 'Otimize a elaboração de Réplicas com nossa ferramenta eficiente. Este card leva você a uma lista de réplicas já criadas, permitindo que você visualize, edite ou crie novos documentos. Utilizando a tecnologia avançada do advtechpro.ai, preencha automaticamente os detalhes necessários e gere um documento Word preciso e personalizado para suas necessidades jurídicas.',
        },
    ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card
            direction='column'
            w='100%'
            px='0px'
            p="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
            style={{ fontFamily: 'Encode Sans Condensed' }}
        >
            {!tabCard ? (
                <Box
                    display="flex"
                    flexWrap="wrap"
                    gap="10px"
                    p="10px"
                    justifyContent="space-between"
                >
                    {(user?.plan?.plan === 'basic' ? cards.filter((v) => v.id !== 4 && v.id !== 5) : cards).map((tab, i) => (
                        <Card
                            direction='column'
                            minW="49%"
                            maxW="49%"
                            boxShadow="md"
                            style={{ fontFamily: 'Encode Sans Condensed' }}
                            key={tab.title}
                            minH="180px"
                            p="20px"
                            justifyContent="center"
                            border={`1px solid #0090b3`}
                            cursor="pointer"
                            onClick={() => {
                                setTabCard(tab.id);
                            }}
                        >
                            <Icon as={SiGoogledocs} fontSize="2xl" color='#0090b3' mb="5px" />
                            <Text color="#0090b3" fontSize="2xl" fontWeight='700' mb="5px">
                                {tab.title}
                            </Text>
                            <Text color="inherit" fontSize="md" fontWeight='500'>
                                {tab.description}
                            </Text>
                        </Card>
                    ))}
                </Box>
            ) : (
                <ColumnsTable
                    columnsData={columnsDataColumns}
                    tableData={list}
                    getList={getList}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    id={tabCard}
                    searchTxt={searchTxt}
                    setSearchTxt={setSearchTxt}
                    setTabCard={setTabCard}
                />
            )}
            {/* <Tabs isFitted variant='enclosed' colorScheme="purple" onChange={(index) => setTabIndex(index)}>
                <TabList mb='1em'>
                    <Tab _selected={{ color: "white", bg: "main.500" }}>Petições iniciais</Tab>
                    <Tab _selected={{ color: "white", bg: "main.500" }}>Contestações</Tab>
                    <Tab _selected={{ color: "white", bg: "main.500" }}>Recursos</Tab>
                    <Tab _selected={{ color: "white", bg: "main.500" }}>Contrato</Tab>
                    <Tab _selected={{ color: "white", bg: "main.500" }}>Parecer</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <ColumnsTable
                            columnsData={columnsDataColumns}
                            tableData={list}
                            getList={getList}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            id={1}
                            searchTxt={searchTxt}
                            setSearchTxt={setSearchTxt}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ColumnsTable
                            columnsData={columnsDataColumns}
                            tableData={list}
                            getList={getList}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            id={2}
                            searchTxt={searchTxt}
                            setSearchTxt={setSearchTxt}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ColumnsTable
                            columnsData={columnsDataColumns}
                            tableData={list}
                            getList={getList}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            id={3}
                            searchTxt={searchTxt}
                            setSearchTxt={setSearchTxt}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ColumnsTable
                            columnsData={columnsDataColumns}
                            tableData={list}
                            getList={getList}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            id={4}
                            searchTxt={searchTxt}
                            setSearchTxt={setSearchTxt}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ColumnsTable
                            columnsData={columnsDataColumns}
                            tableData={list}
                            getList={getList}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            id={5}
                            searchTxt={searchTxt}
                            setSearchTxt={setSearchTxt}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs> */}
        </Card>
    </Box>
  );
}
