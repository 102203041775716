import { api } from './api';

export async function AnalysisPDF(pdf, type, token) {
    try {
        const body = { pdf, type };
        const configs = {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const response = await api.patch('/documents/pdf', body, configs);
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
