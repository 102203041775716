import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const AppElement = ({ setPaymentMethod }) => {
    const elements = useElements();
    const stripe = useStripe();

    const onClickSubmit = async () => {
        try {
            const response = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });
            if (response.error) {
                return toast(`Ocorreu um erro ao salvar seus dados de cartão: ${JSON.stringify(response.error)}`, {
                    type: 'error'
                });
            }
            setPaymentMethod(response.paymentMethod.id);
        } catch (error) {
          if (error?.response?.error) {
              return toast(`Ocorreu um erro ao salvar seus dados de cartão: ${JSON.stringify(error.response.error)}`, {
                  type: 'error'
              });
          }
        }
    };
    return (
        <form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <CardElement
              onChange={(txt) => {
                if (txt.complete && !txt?.error?.message) {
                  onClickSubmit();
                }
              }}
            />
            <ToastContainer />
        </form>
    );
};
