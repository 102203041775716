// Chakra imports
import {
    Avatar,
    Box,
    // Flex,
    Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useAuthContext } from "contexts/SidebarContext";
import Dropzone from 'views/admin/profile/components/Dropzone';
import React from "react";
import { EditUser } from "services/users";
import { useAuth } from "hooks/use-auth";
import { ToastContainer, toast } from "react-toastify";

export default function Banner(props) {
  const {
    banner,
    // avatar, name, job, posts, followers, following
} = props;
  const { user } = useAuthContext();
  const auth = useAuth();
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const borderSecondary = "white";
  const textColorSecondary = "secondaryGray.900";
//   const borderColor = useColorModeValue(
//     "white !important",
//     "#111C44 !important"
//   );
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
        <Dropzone
            onImageSelect={async (file) => {
                const response = await EditUser({ name: user?.user?.name, email: user?.user?.email, photo: file[0] }, user?.user?.id, user?.token);
                if (response?.status === 200 || response?.status === 201) {
                    await auth.updateDataUser({ ...user, user: {...user.user, photo: file[0] }});
                    toast(response?.message, {
                        type: 'success'
                    });
                }
            }}
            accept="image/png, image/jpeg, image/jpg, image/avif, image/webp"
            noStyle
            content={
                <Avatar
                  mx='auto'
                  h='87px'
                  w='87px'
                  mt='-43px'
                  border='4px solid'
                  bg="#59ebfd"
                  name={user?.user?.name}
                  color={borderSecondary}
                  cursor="pointer"
                  src={user?.user?.photo || ''}
                />
            }
        />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px'>
        {user?.user?.name}
      </Text>
      <Text color={textColorSecondary} fontSize='md'>
        {user?.user?.email}
      </Text>
      <Text color={textColorSecondary} fontSize='md'>
        Master
      </Text>
      <ToastContainer />
      {/* <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Posts
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Followers
          </Text>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Following
          </Text>
        </Flex>
      </Flex> */}
    </Card>
  );
}
