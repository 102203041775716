import { api } from './api';

export async function ListUsers(searchTxt, idUser, page, token) {
    try {
        let baseURL = '/users?limit=10';
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (idUser) {
            baseURL = `${baseURL}&idUser=${idUser}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditUser(body, idUser, token) {
    try {
        const response = await api.put(`/users?idUser=${idUser}`, body, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function SendEmailMarketing(body, idUser, token) {
    try {
        const response = await api.patch(`/users/emailMarketing?idUser=${idUser}`, body, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
