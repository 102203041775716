import { api } from './api';

export async function ListPecaAvulsa(idUser, page, token) {
    try {
        let baseURL = '/pecaAvulsa?limit=10';
        if (idUser) {
            baseURL = `${baseURL}&idUser=${idUser}`
        }
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
