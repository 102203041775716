/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { AuthProvider } from 'contexts/SidebarContext';
import { useAuthContext } from 'contexts/SidebarContext';
import LoadingLayout from 'views/loading';
import { useAuth } from 'hooks/use-auth';

const Routes = () => {
    const { user } = useAuthContext();
    const auth = useAuth();
    const getIdAffiliate = window.location.href.split('idAffiliate=')[1];
    const getIdPlan = window.location.href.split('idPlan=')[1];
    if (getIdAffiliate) {
      window.sessionStorage.setItem('@advtech_idAffiliate', getIdAffiliate);
    }
    if (getIdPlan) {
      window.sessionStorage.setItem('@advtech_idPlan', getIdPlan);
    }
    if (getIdAffiliate || getIdPlan) {
      auth.signOut();
    }

    const [openSession, setOpenSession] = useState(false);

    useEffect(() => {
        if (!openSession) {
            verifyUser();
        }
    }, [user]);

    const verifyUser = () => {
        try {
            const dataUserSave = window.localStorage.getItem('@advtech_authenticated');
            const dataUserParse = JSON.parse(dataUserSave);
            if (dataUserParse.tokenRefresh && user) {
                setOpenSession(true);
            }
        } catch (error) {
            setOpenSession(true);
        }
    };

    if (!openSession) {
        return (
            <>
                <Route path={`/loading`} component={LoadingLayout} />
                <Redirect from='/' to='/loading' />
            </>
        );
    }
    if (user?.token) {
        return (
            <>
                <Route path={`/admin`} component={AdminLayout} />
                <Redirect from='/' to={!user?.plan?.stripe_plan ? '/admin/plans' : '/admin/default'} />
            </>
        );
    }
    return (
        <>
            <Route path={`/auth`} component={AuthLayout} />
            <Redirect from='/' to='/auth/sign-in' />
        </>
    )
}

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
            <AuthProvider>
                <ThemeEditorProvider>
                    <HashRouter>
                        <Switch>
                            <Routes />
                        </Switch>
                    </HashRouter>
                </ThemeEditorProvider>
            </AuthProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
