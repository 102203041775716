'use client';
/*eslint-disable*/

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Img,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { MdAutoAwesome, MdPerson } from 'react-icons/md';
import Bg from '../../../assets/img/chat/bg-image.png';
import Card from 'components/card/Card';
import { ListPecaAvulsa } from 'services/pecas';
import { useAuthContext } from 'contexts/SidebarContext';
import Dropzone from 'views/admin/profile/components/Dropzone';
import { io } from 'socket.io-client';
import { Field, Form, Formik } from 'formik';
import { getNameWithExtensionFromUrl } from 'utils/masks';
import { FaMicrophoneAlt, FaMicrophoneAltSlash } from 'react-icons/fa';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

export default function Pecas() {
    const { user } = useAuthContext();

  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  const inputColor = useColorModeValue('navy.700', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const textColor = useColorModeValue('navy.700', 'white');
  const placeholderColor = useColorModeValue(
    { color: 'gray.500' },
    { color: 'whiteAlpha.600' },
  );

  const [inputCode, setInputCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [completeMessageLocal, setCompleteMessageLocal] = useState('');
  const [allPecas, setAllPecas] = useState([]);
  const [infoPeca, setInfoPeca] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [isNewChat, setIsNewChat] = useState(false);
  const messagesEndRef = useRef(null);

useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [allMessages]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async (isPost) => {
    const response = await ListPecaAvulsa(user?.user?.id, 1, user?.token);
    const list = response?.result?.list || [];
    if (list?.length > 0) {
        setAllPecas(list);
        if (isPost) {
            setInfoPeca(list[0]);
        }
    }
  };

  const handleTranslate = async () => {
    setLoading(true);
    const newMessage = {
        content: inputCode,
        role: 'user',
    };
    setAllMessages([...allMessages, newMessage]);
    setInputCode('');

    // setCompleteMessageLocal(`Resposta de teste: ${uuidv4()}`);
    const socket = io('https://api.advtechpro.tech');
    socket.emit('pecaAvulsa', {
        msg: newMessage.content,
        idUser: user?.user?.id,
        idChat: infoPeca.id,
    });

    var completeMessage = '';
    socket.on('receberPecaAvulsa', function (char) {
        completeMessage += char;
        setCompleteMessageLocal(completeMessage);
    });

    socket.on('terminouPecaAvulsa', function (char) {
        if (char.finish) {
            finishMessageSocket(char.content);
            socket.disconnect();
        }
    });
  };

  const finishMessageSocket = (message, isNewChat) => {
    const newMessage = {
        content: message,
        role: 'assistant',
    };
    setAllMessages((prevState) => [...prevState, newMessage]);
    setCompleteMessageLocal('');
    setLoading(false);
    getList(isNewChat);
    setOpenModal(false);
    setIsNewChat(false);
  };
  
  const handleChange = (Event) => {
    setInputCode(Event.target.value);
  };
  
  const onClose = () => {
    setOpenModal(false);
  };

  const handleSelectPeca = (peca) => {
    setInfoPeca(peca);
    setAllMessages(JSON.parse(peca.data));
  };

    const validationSchema = Yup.object().shape({
        pdf: Yup.array().required('Preencha com um PDF'),
    });

    const handleOpenSocketCreate = (newChat) => {
        setIsNewChat(true);
        const socket = io('https://api.advtechpro.tech');
        socket.emit('pecaAvulsa', newChat);
        var completeMessage = '';
        socket.on('receberPecaAvulsa', function (char) {
            completeMessage += char;
            setCompleteMessageLocal(completeMessage);
            if (char) {
                setOpenModal(false);
                setLoading(false);
            }
        });
        socket.on('terminouPecaAvulsa', function (char) {
            if (char.finish) {
                finishMessageSocket(char.content, true);
                socket.disconnect();
            }
        });
    };


    const [isRecording, setIsRecording] = useState(false);
    const recognitionRef = useRef(null);

    const startRecording = () => {
        if (recognitionRef.current) {
            recognitionRef.current.start();
            setIsRecording(true);
        }
    };

    const stopRecording = () => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            setIsRecording(false);
        }
    };

    if (!recognitionRef.current && SpeechRecognition) {
        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = false;

        recognitionRef.current.onerror = (event) => {
            console.error("Error occurred in recognition: ", event.error);
            setIsRecording(false);
        };

        recognitionRef.current.onend = () => {
            if (isRecording) {
                recognitionRef.current.start();
            } else {
                setIsRecording(false);
            }
        };
    }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Modal isOpen={openModal} onClose={onClose} isCentered scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent style={{ fontFamily: 'Encode Sans Condensed' }}>
                <ModalHeader>
                    Criar nova peça
                    <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para criar uma peça`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column">
                    <Formik
                        initialValues={{
                            pdf: []
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            try {
                                const newChat = {
                                    idUser: user?.user?.id,
                                    pdf: values.pdf[0]
                                };
                                setLoading(true);
                                handleOpenSocketCreate(newChat);
                                helpers.setStatus({ success: true });
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                setLoading(false);
                            } finally {
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column' }}>
                                <Field name='pdf'>
                                    {({ form }) => (
                                        <FormControl alignSelf="center" maxW="50%" isInvalid={form.errors.pdf && form.touched.pdf}>
                                            <Flex display="flex" flexDirection="column" maxW="100%">
                                                <Dropzone
                                                    minH="60px"
                                                    w="100%"
                                                    borderRadius="10px"
                                                    mt="24px"
                                                    onImageSelect={(file) => {
                                                        form.setFieldValue('pdf', file);
                                                    }}
                                                    accept="application/pdf"
                                                    content={
                                                        <Box display="flex" flexDirection="column" gap="10px">
                                                            {form?.values?.pdf?.length > 0 ? (
                                                                <Text color='blue.500' fontSize='xs' fontWeight='400'>{getNameWithExtensionFromUrl(`${form?.values?.pdf[0]?.substring(0, form?.values?.pdf[0]?.length - 4 > 30 ? 30 : form?.values?.pdf[0]?.length - 4)}${form?.values?.pdf[0].slice(-4)}`)}</Text>
                                                            ) : (
                                                                <>
                                                                    <Text color='blue.500' fontSize='xs' fontWeight='400'>Clique aqui para selecionar um documento</Text>
                                                                    <Text color='secondaryGray.700' fontSize='10px' fontWeight='400'>Formato aceito: PDF</Text>
                                                                </>
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            </Flex>
                                            <FormErrorMessage>{form.errors.pdf}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Button
                                    fontSize='sm'
                                    variant='brand'
                                    alignSelf="flex-end"
                                    w="150px"
                                    minH="40px"
                                    mb="16px"
                                    mt="16px"
                                    isLoading={loading}
                                    type="submit"
                                >
                                    Criar peça
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
        <Card
            direction='column'
            w='100%'
            px='0px'
            p="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
            style={{ fontFamily: 'Encode Sans Condensed' }}
        >
            <Flex
                w="100%"
                position="relative"
            >
                <Flex
                    direction="column"
                    mx="auto"
                    w={infoPeca?.pdf ? "20%" : "30%"}
                    minH={{ base: '75vh', '2xl': '85vh' }}
                    maxH={{ base: '75vh', '2xl': '85vh' }}
                    overflowY="auto"
                >
                    <Flex
                        variant="primary"
                        py="20px"
                        px="16px"
                        fontSize="sm"
                        borderRadius="0px"
                        ms="auto"
                        width="100%"
                        maxW="100%"
                        mb="10px"
                        bg="main.900"
                        cursor="pointer"
                        onClick={() => {
                            setOpenModal(true);
                            setInputCode('');
                            setAllMessages([]);
                            setCompleteMessageLocal('');
                            setInfoPeca(null);
                        }}
                    >
                        <Text
                            color="white"
                            fontWeight="700"
                            fontSize={{ base: 'sm', md: 'md' }}
                        >
                            CRIAR NOVA PEÇA
                        </Text>
                    </Flex>
                    {allPecas.map((peca) => {
                        const content = (JSON.parse(peca.data)[0].content || '');
                        return (
                            <Flex
                                variant="primary"
                                py="20px"
                                px="16px"
                                fontSize="sm"
                                borderRadius="0px"
                                ms="auto"
                                width="100%"
                                maxW="100%"
                                mb="10px"
                                bg="main.500"
                                color="white"
                                cursor="pointer"
                                onClick={() => handleSelectPeca(peca)}
                                key={peca.id}
                            >
                                <Text
                                    color={textColor}
                                    fontWeight="600"
                                    fontSize={{ base: 'sm', md: 'md' }}
                                >
                                    {`${content?.substring(0, 80)}${content?.length > 80 ? '...' : ''}`}
                                </Text>
                            </Flex>
                        );
                    })}
                </Flex>
                {infoPeca?.pdf ? (
                    <Flex
                        direction="column"
                        mx="auto"
                        w="40%"
                        minH={{ base: '75vh', '2xl': '85vh' }}
                        maxH={{ base: '75vh', '2xl': '85vh' }}
                        overflowY="auto"
                        position="relative"
                        pl="10px"
                    >
                        <iframe src={infoPeca.pdf} height="100%" width="100%" />
                    </Flex>
                ) : null}
                <Flex
                    direction="column"
                    mx="auto"
                    w={infoPeca?.pdf ? "40%" : "70%"}
                    minH={{ base: '75vh', '2xl': '85vh' }}
                    maxH={{ base: '75vh', '2xl': '85vh' }}
                    overflowY="auto"
                    position="relative"
                    pl="10px"
                >
                    <Img
                        src={Bg}
                        position={'absolute'}
                        w="350px"
                        left="50%"
                        top="50%"
                        transform={'translate(-50%, -50%)'}
                        zIndex={0}
                    />
                    {/* Main Box */}
                    <Flex
                        direction="column"
                        w="100%"
                        mx="auto"
                        display="flex"
                        mb={'auto'}
                        zIndex={0}
                    >
                        {allMessages.map((message, index) => {
                            if (message.role === 'user') {
                                return (
                                    <Flex w="100%" align={'center'} mb="10px" mt="10px" key={index}>
                                        <Flex
                                            borderRadius="full"
                                            justify="center"
                                            align="center"
                                            bg={'transparent'}
                                            border="1px solid"
                                            borderColor={borderColor}
                                            me="20px"
                                            h="40px"
                                            minH="40px"
                                            minW="40px"
                                        >
                                            <Icon
                                                as={MdPerson}
                                                width="20px"
                                                height="20px"
                                                color={brandColor}
                                            />
                                        </Flex>
                                        <Flex
                                            p="22px"
                                            border="1px solid"
                                            borderColor={borderColor}
                                            borderRadius="14px"
                                            w="100%"
                                            zIndex={'2'}
                                        >
                                            <Text
                                                color={textColor}
                                                fontWeight="600"
                                                fontSize={{ base: 'sm', md: 'md' }}
                                                lineHeight={{ base: '24px', md: '26px' }}
                                            >
                                                {message.content}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                );
                            }
                            return (
                                <Flex w="100%" key={index} mb="10px" mt="10px">
                                    <Flex
                                        borderRadius="full"
                                        justify="center"
                                        align="center"
                                        bg={'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)'}
                                        me="20px"
                                        h="40px"
                                        minH="40px"
                                        minW="40px"
                                    >
                                        <Icon
                                            as={MdAutoAwesome}
                                            width="20px"
                                            height="20px"
                                            color="white"
                                        />
                                    </Flex>
                                    <Text
                                        color={textColor}
                                        fontWeight="600"
                                        fontSize={{ base: 'sm', md: 'md' }}
                                        lineHeight={{ base: '24px', md: '26px' }}
                                    >
                                        {message.content}
                                    </Text>
                                </Flex>
                            );
                        })}
                        {!completeMessageLocal ? null : (
                            <Flex w="100%">
                                <Flex
                                    borderRadius="full"
                                    justify="center"
                                    align="center"
                                    bg={'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)'}
                                    me="20px"
                                    h="40px"
                                    minH="40px"
                                    minW="40px"
                                >
                                    <Icon
                                        as={MdAutoAwesome}
                                        width="20px"
                                        height="20px"
                                        color="white"
                                    />
                                </Flex>
                                <Text
                                    color={textColor}
                                    fontWeight="600"
                                    fontSize={{ base: 'sm', md: 'md' }}
                                    lineHeight={{ base: '24px', md: '26px' }}
                                >
                                    {completeMessageLocal}
                                </Text>
                            </Flex>
                        )}
                        <div ref={messagesEndRef} />
                    </Flex>
                    {/* Chat Input */}
                    <Flex
                        ms={{ base: '0px', xl: '60px' }}
                        justifySelf={'flex-end'}
                        alignItems="center"
                        m="10px"
                    >
                        <Input
                            minH="54px"
                            h="100%"
                            border="1px solid"
                            borderColor={borderColor}
                            borderRadius="45px"
                            p="15px 20px"
                            me="10px"
                            fontSize="md"
                            fontWeight="500"
                            _focus={{ borderColor: 'none' }}
                            color={inputColor}
                            _placeholder={placeholderColor}
                            placeholder="Digite sua mensagem aqui..."
                            onChange={handleChange}
                            value={inputCode}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && infoPeca.id && inputCode.length) {
                                    handleTranslate();
                                }
                            }}
                        />
                        <Icon
                            as={isRecording ? FaMicrophoneAlt : FaMicrophoneAltSlash}
                            style={{ marginRight: 10, height: 30, width: 30 }}
                            cursor="pointer"
                            onClick={() => {
                                if (isRecording) {
                                    stopRecording();
                                }
                                if (!isRecording) {
                                    startRecording();
                                }

                                recognitionRef.current.onresult = async (event) => {
                                    const transcript = event.results[event.resultIndex][0].transcript;
                                    const todoOConteudo = transcript.split('');
                                    for (let index = 0; index < todoOConteudo.length; index++) {
                                        const h = todoOConteudo[index];
                                        setInputCode((prevState) => prevState + h);
                                        await new Promise((resolve) => setTimeout(() => resolve(true), 10));
                                    }
                                };
                            }}
                        />
                        <Button
                            variant="primary"
                            py="20px"
                            px="16px"
                            fontSize="md"
                            borderRadius="45px"
                            ms="auto"
                            w={{ base: '160px', md: '210px' }}
                            h="54px"
                            bg="#59ebfd"
                            color="white"
                            onClick={handleTranslate}
                            isLoading={loading ? true : false}
                            disabled={loading || !inputCode.length || !infoPeca?.id}
                        >
                            Enviar
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    </Box>
  );
}
