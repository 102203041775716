import axios from 'axios';
import fileDownload from 'js-file-download';

export async function getDownloadRemoteFile(file) {
    await axios.get(file, {
        responseType: 'arraybuffer',
    }).then((res) => {
        fileDownload(res.data, 'Arquivo.docx');
    });
}
