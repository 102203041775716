/* eslint-disable */
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Textarea, useColorModeValue } from "@chakra-ui/react";
import { useAuthContext } from "contexts/SidebarContext";
import * as Yup from 'yup';
import { Field, Form, Formik } from "formik";
import { SendEmailMarketing } from "services/users";
import { ToastContainer, toast } from "react-toastify";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("main.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("main.500", "main.400");
  const bgTextArea = useColorModeValue("white", "transparent");
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('O título é obrigatório'),
        description: Yup.string().required('A descrição é obrigatória'),
    });

  const { routes } = props;
  const { user } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

    const onClose = () => {
        setIsOpen(false);
    };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
        const renderWithIcon = () => (
            <HStack
            spacing={
              activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
            }
            py='5px'
            ps='10px'>
            <Flex w='100%' alignItems='center' justifyContent='center'>
              <Box
                color={
                  activeRoute(route.path.toLowerCase())
                    ? activeIcon
                    : textColor
                }
                me='18px'
              >
                {route.icon}
              </Box>
              <Text
                me='auto'
                pb="4px"
                color={
                  activeRoute(route.path.toLowerCase())
                    ? activeColor
                    : textColor
                }
                fontSize="lg"
                fontWeight={
                  activeRoute(route.path.toLowerCase())
                    ? "bold"
                    : "normal"
                }>
                {route.name}
              </Text>
            </Flex>
            <Box
              h='36px'
              w='4px'
              bg={
                activeRoute(route.path.toLowerCase())
                  ? brandColor
                  : "transparent"
              }
              borderRadius='5px'
            />
          </HStack>
        );
        if (route.hidden) {
            return null;
        }
        if (route.linkRedirect !== undefined) {
            return (
                <Box
                    onClick={() => {
                        if (route.linkRedirect) {
                            window.open(route.linkRedirect);
                        } else {
                            setIsOpen(true);
                        }
                    }}
                    cursor="pointer"
                >
                    {renderWithIcon()}
                    <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="md">
                        <ToastContainer/>
                        <ModalOverlay />
                        <ModalContent style={{ fontFamily: 'Encode Sans Condensed' }}>
                            <ModalHeader>
                                Suporte via e-mail
                                <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>Preenchas as informações abaixo para enviar seus dados de suporte</Text>
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody display="flex" flexDirection="column">
                                <Flex direction='column'>
                                    <Formik
                                        initialValues={{ name: user?.user?.name || '', email: user?.user?.email || '', password: '', confirmPassword: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={async (values, helpers) => {
                                            try {
                                                const body = {
                                                    title: values.title,
                                                    description: values.description,
                                                };
                                                const response = await SendEmailMarketing(body, user?.user?.id, user?.token);
                                                if (response?.status === 200 || response?.status === 201) {
                                                    helpers.setSubmitting(false);
                                                    toast(response?.message, {
                                                        type: 'success'
                                                    });
                                                    setTimeout(() => {
                                                        setIsOpen(false);
                                                    }, 1000);
                                                } else {
                                                    helpers.setStatus({ success: false });
                                                    helpers.setErrors({ submit: response?.message });
                                                    helpers.setSubmitting(false);
                                                }
                                            } catch (err) {
                                                helpers.setStatus({ success: false });
                                                helpers.setErrors({ submit: err.message });
                                                helpers.setSubmitting(false);
                                            }
                                        }}
                                    >
                                        {(props) => (
                                            <Form style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Field name='title'>
                                                    {({ field, form }) => (
                                                        <FormControl isInvalid={form.errors.title && form.touched.title}>
                                                            <FormLabel
                                                                display='flex'
                                                                ms='4px'
                                                                fontSize='sm'
                                                                fontWeight='500'
                                                                color={textColor}
                                                                mb='8px'
                                                            >
                                                                Título
                                                            </FormLabel>
                                                            <Input
                                                                {...field}
                                                                variant='auth'
                                                                fontSize='sm'
                                                                ms={{ base: "0px", md: "0px" }}
                                                                placeholder='Título'
                                                                fontWeight='500'
                                                                size='lg'
                                                            />
                                                            <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                                <Field name='description'>
                                                    {({ field, form }) => (
                                                        <FormControl mt="16px" mb="16px" sInvalid={form.errors.description && form.touched.description}>
                                                            <Flex>
                                                                <FormLabel
                                                                    display='flex'
                                                                    ms='4px'
                                                                    fontSize='sm'
                                                                    fontWeight='500'
                                                                    color={textColor}
                                                                    mb='8px'
                                                                >
                                                                    Descrição
                                                                </FormLabel>
                                                            </Flex>
                                                            <Textarea
                                                                {...field}
                                                                variant='auth'
                                                                fontSize='sm'
                                                                ms={{ base: "0px", md: "0px" }}
                                                                placeholder='Relate seu problema'
                                                                fontWeight='500'
                                                                size='lg'
                                                                borderWidth="1px"
                                                                resize="none"
                                                                borderRadius="20px"
                                                                minH="150px"
                                                                bg={bgTextArea}
                                                                css={{
                                                                    '::placeholder': {
                                                                        color: '#A3AED0',
                                                                        fontWeight: 400,
                                                                    }
                                                                }}
                                                            />
                                                            <FormErrorMessage mb='24px'>{form.errors.description}</FormErrorMessage>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                                {props.errors.submit && (
                                                    <Text
                                                        color={props.status.success ? 'green.500' : 'red.500'}
                                                        fontSize='small'
                                                        fontWeight='400'
                                                        marginTop="20px"
                                                    >
                                                        {props.errors.submit}
                                                    </Text>
                                                )}
                                                <Button
                                                    variant='brand'
                                                    isLoading={props.isSubmitting}
                                                    type="submit"
                                                    alignSelf="flex-end"
                                                    w="150px"
                                                    marginTop="24px"
                                                    marginBottom="10px"
                                                >
                                                    Enviar dados
                                                </Button>
                                            </Form>
                                        )}
                                    </Formik>
                                </Flex>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </Box>
            );
        }
      if (route.category) {
        return (
          <>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight='bold'
              mx='auto'
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt='18px'
              pb='12px'
              key={index}>
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      } else if (route.disabled || (!user?.plan?.stripe_plan && route.path !== '/plans') || (user?.plan?.plan !== 'premium' && route.path === '/pecas')) {
        return (
            <Box style={{ opacity: 0.5 }}>
                {renderWithIcon()}
            </Box>
        )
      } else if (
        route.layout === "/admin" ||
        route.layout === "/client" ||
        route.layout === "/auth"
      ) {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            {route.icon ? (
              <Box>
                {renderWithIcon()}
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }>
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='main.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
