/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Button,
//   Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
// import { HSeparator } from "components/separator/Separator";
import ADVTECHPRO_LOGO from 'assets/img/ADVTECHPRO_LOGO.png'
// Assets
// import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useAuth } from 'hooks/use-auth';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

function SignIn() {
    const auth = useAuth();

    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("main.500", "white");
    const brandStars = useColorModeValue("main.500", "main.400");
    // const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    // const googleText = useColorModeValue("navy.700", "white");
    // const googleHover = useColorModeValue(
    //     { bg: "gray.200" },
    //     { bg: "whiteAlpha.300" }
    // );
    // const googleActive = useColorModeValue(
    //     { bg: "secondaryGray.300" },
    //     { bg: "whiteAlpha.200" }
    // );
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
        password: Yup.string().required('Senha é obrigatória'),
    });
    
    const getIdAffiliate = window.sessionStorage.getItem('@advtech_idAffiliate');
    const getIdPlan = window.sessionStorage.getItem('@advtech_idPlan');

    useEffect(() => {
        if (getIdAffiliate || getIdPlan) {
            window.location.replace('/#/auth/sign-up');
        }
    }, []);

    return (
      <Flex
        h="100vh"
        w='100%'
        alignItems="center"
        justifyContent="space-around"
        overflowY="auto"
        direction="row">
            <Flex
              w='50%'
              h="100%"
              alignItems="center"
              justifyContent="center"
              display={{ sm: 'none', md: 'flex'}}
              bgColor="#0f111f"
            >
              <Image h="60%" w="60%" objectFit="contain" src={ADVTECHPRO_LOGO} />
            </Flex>
            <Flex
                w='50%'
                h='auto'
                alignItems='center'
                justifyContent='center'
                display="flex"
                flexDirection='column'
                style={{ fontFamily: 'Encode Sans Condensed' }}
            >
                <Flex 
                  zIndex='2'
                  direction='column'
                  w={{ base: "100%", md: "420px" }}
                  maxW='100%'
                  background='transparent'
                  borderRadius='15px'
                  mx={{ base: "auto", lg: "unset" }}
                  me='auto'
                  mb={{ base: "20px", md: "auto" }}
                >
                    <Heading style={{ fontFamily: 'Encode Sans Condensed' }} color={textColor} fontSize='5xl' mb='10px'>
                        Login
                    </Heading>
                    <Text
                        mb='36px'
                        ms='4px'
                        color={textColorSecondary}
                        fontWeight='400'
                        fontSize='lg'
                    >
                        Digite seu e-mail e senha para entrar!
                    </Text>
                </Flex>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "420px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}
                >
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            try {
                                const response = await auth.signIn(values.email, values.password);
                                helpers.setSubmitting(false);
                                if (response?.status !== 200) {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response.message });
                                    helpers.setSubmitting(false);
                                } else {
                                    // window.location.replace('/')
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form>
                                <Field name='email'>
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.email && form.touched.email}>
                                            <FormLabel
                                                display='flex'
                                                ms='4px'
                                                fontSize='md'
                                                fontWeight='500'
                                                color={textColor}
                                                mb='8px'
                                            >
                                                E-mail<Text color={brandStars}>*</Text>
                                            </FormLabel>
                                            <Input
                                                {...field}
                                                variant='auth'
                                                fontSize='md'
                                                ms={{ base: "0px", md: "0px" }}
                                                type='email'
                                                placeholder='Seu e-mail'
                                                fontWeight='500'
                                                size='lg'
                                            />
                                            <FormErrorMessage mb='24px'>{form.errors.email}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='password'>
                                    {({ field, form }) => (
                                        <FormControl marginTop="24px" isInvalid={form.errors.password && form.touched.password}>
                                            <FormLabel
                                                ms='4px'
                                                fontSize='md'
                                                fontWeight='500'
                                                color={textColor}
                                                display='flex'>
                                                Senha<Text color={brandStars}>*</Text>
                                            </FormLabel>
                                            <InputGroup size='md'>
                                                <Input
                                                    {...field}
                                                    fontSize='md'
                                                    placeholder='Sua senha'
                                                    size='lg'
                                                    type={show ? "text" : "password"}
                                                    variant='auth'
                                                />
                                                <InputRightElement display='flex' alignItems='center' mt='4px'>
                                                    <Icon
                                                        color={textColorSecondary}
                                                        _hover={{ cursor: "pointer" }}
                                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                        onClick={handleClick}
                                                    />
                                                </InputRightElement>
                                            </InputGroup>
                                            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Flex marginTop="24px" justifyContent='space-between' align='center' mb='24px'>
                                    <NavLink to='/auth/forgot-password'>
                                        <Text
                                            color={textColorBrand}
                                            fontSize='md'
                                            w='140px'
                                            fontWeight='500'
                                        >
                                            Esqueceu sua senha?
                                        </Text>
                                    </NavLink>
                                </Flex>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="24px"
                                        mb="24px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    fontSize='md'
                                    variant='brand'
                                    fontWeight='500'
                                    w='100%'
                                    h='50'
                                    mb='24px'
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                >
                                    Entrar
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <Flex
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='start'
                        maxW='100%'
                        mt='0px'
                    >
                        <Text color={textColorDetails} fontWeight='400' fontSize='md'>
                            Não registrado ainda?
                            <NavLink to='/auth/sign-up'>
                                <Text
                                    color={textColorBrand}
                                    as='span'
                                    ms='5px'
                                    fontWeight='500'
                                >
                                    Crie a sua conta aqui
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default SignIn;
