/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    HStack,
    Heading,
    Text,
    VStack,
    List,
    Button,
    SimpleGrid,
    Stack,
    Icon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Tag,
    useColorModeValue,
  } from '@chakra-ui/react'
  import { useState } from 'react';
  import { MdCheck, MdClose } from 'react-icons/md';
import { useAuthContext } from 'contexts/SidebarContext';
import { useAuth } from 'hooks/use-auth';
import { priceUnmask } from 'utils/masks';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CancelPlan } from 'services/stripe';
import { lightFormat } from 'date-fns';
import { SignPlan } from 'services/stripe';
  
function PriceWrapper(props) {
    const { children } = props

    return (
        <Box
            mb={4}
            shadow="1px 1px 12px 0 #00d3ff"
            borderWidth="2px"
            alignSelf={{ base: 'center', lg: 'flex-start' }}
            borderColor={"#00d3ff"}
            borderRadius={'12px'}
            minH="488px"
        >
            {children}
        </Box>
    )
}
  
export default function Pricing() {
    const allDescriptions = [
        'Disponível 24 horas por dia, 7 dias por semana',
        'Até x peças disponíveis',
        'Interações via ChatAdvTech',
        'Entrada de comando por texto',
        'Entrada de comando por voz',
        'Cadastro de Autor e Réu',
        'Petições Iniciais',
        'Contestações',
        'Réplica',
        'Recursos',
        'Pareceres jurídicos',
        'Contratos',
        'Converse com o PDF',
        'Biblioteca de Prompts',
        'Suporte por e-mail em até x',
        'Suporte por WhatsApp em até 1 dia útil',
        'Elaboração de peças processuais com jurisprudências reais',
        'Participação da Comunidade Advocacia Com Inteligência Artificial - Com Prof. Túlio Silveira (até 2 profissionais por Escritório)',
    ];

    const allPlans = [
        {
            id: 1,
            idsPlans: ['$2a$10$O9vdb1W/KTF8cfFFKWGIRuPxw4f1LYCoXC9XtTOBydYIb6cULWcU6', '$2a$10$yPLldZXuYe0D7kCBxn87YObWewbl8wa5IttLxCflyajaw0MnMmvhO'],
            name: 'Plano Básico',
            plan: 'basic',
            priceMonthFinal: 9700,
            priceYearlyFinal: 104760,
            priceMonth: 'R$ 97,00',
            priceYearlyFull: 'R$ 1.164,00',
            priceYearly: 'R$ 1.047,60',
            description: [0, 1, 2, 3, 5, 6, 7, 8, 9, 14, 16],
            pecas: '30',
            day: 3,
        },
        {
            id: 2,
            idsPlans: ['$2a$10$teaJsmrfdE1q1m/YbmfodOVfJG4nS2vDnpls8Hqt9YBtc/1/jC/pq', '$2a$10$i5okQvA9axO6qhCGDJtWnOm2hgmujG0n905g69CYV5T1owyOC/D06'],
            name: 'Plano Advanced',
            plan: 'advanced',
            priceMonthFinal: 14700,
            priceYearlyFinal: 158760,
            priceMonth: 'R$ 147,00',
            priceYearlyFull: 'R$ 1.764,00',
            priceYearly: 'R$ 1.587,60',
            description: [0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 14, 16],
            pecas: '60',
            day: 2,
        },
        {
            id: 3,
            idsPlans: ['$2a$10$vvqc3ude8updl0oGrgoh8e3kEwM3lhhd8GAtzkdpvYoI5ovwKr2K2', '$2a$10$Oe3xb1zXPC/xxgFpxezTfucxTFX/yilCDaloYRs8QxIe81ocHVaHu'],
            name: 'Plano Premium',
            plan: 'premium',
            priceMonthFinal: 24700,
            priceYearlyFinal: 266760,
            priceMonth: 'R$ 247,00',
            priceYearlyFull: 'R$ 2.964,00',
            priceYearly: 'R$ 2.667,60',
            description: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
            pecas: '120',
            day: 1,
        },
    ];

    const auth = useAuth();
    const { user } = useAuthContext();

    const [typePlan, setTypePlan] = useState('anual');
    const [loading, setLoading] = useState(false);
    const [planSelect, setPlanSelect] = useState(null);
    const [modalConfirmPlan, setModalConfirmPlan] = useState(false);

    const stylesButtonPlan = {
        cursor: 'pointer',
        width: '45%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: 37,
        borderRadius: 8,
        letterSpacing: 1,
        fontFamily: 'Encode Sans Condensed',
        fontSize: 16,
        fontWeight: 500,
        color: 'black'
    };

    const onCloseModalPlan = () => {
        setModalConfirmPlan(false);
        setPlanSelect(null);
    };

    const textColor = useColorModeValue("navy.700", "white");

    const renderLabel = (item) => {
        if ((user?.plan?.stripe_expired_at || user?.plan?.stripe_plan) && (item.plan === user?.plan?.plan && (typePlan === 'mensal' ? 'month' : 'year') === user?.plan?.period)) {
            return user?.plan?.stripe_cancel_at ? 'CANCELADO' : 'CANCELAR PLANO';
        }
        if ((user?.plan?.stripe_plan && !user?.plan?.plan && !user?.plan?.period) || !user?.plan?.stripe_plan) {
            return 'QUERO ASSINAR';
        };
        if (!user?.plan?.price?.unit_amount) {
            return 'QUERO ASSINAR';
        }
        const formatPrice = user?.plan?.price?.unit_amount / 100;
        if (user?.plan?.stripe_plan && (typePlan === 'mensal' ? priceUnmask(item.priceMonth) > formatPrice : priceUnmask(item.priceYearly) > formatPrice)) {
            return 'FAZER UPGRADE';
        }
        if (user?.plan?.stripe_plan && (typePlan === 'mensal' ? priceUnmask(item.priceMonth) < formatPrice : priceUnmask(item.priceYearly) < formatPrice)) {
            return 'FAZER DOWNGRADE';
        }
    }

    return (
        <Box marginBottom="100px" width="100%" display="flex" flexDirection="column" alignItems="center">
            <Heading>
                <Text
                    color={textColor}
                    fontSize="4xl"
                    style={{ fontWeight: 700, letterSpacing: 1, fontFamily: 'Encode Sans Condensed' }}
                >
                    Como funciona o Acesso a <text style={{ color: '#00d3ff' }}>Advtechpro.ai</text> por Planos?
                </Text>
            </Heading>
            <div style={{ backgroundColor: '#F5F5F5', borderRadius: 12, height: 53, width: 241, marginTop: 20, marginBottom: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                <div
                    style={{
                        ...stylesButtonPlan,
                        backgroundColor: typePlan === 'mensal' ? '#00d3ff' : 'transparent'
                    }}
                    onClick={() => setTypePlan('mensal')}
                >
                    Mensal
                </div>
                <div
                    style={{
                        ...stylesButtonPlan,
                        backgroundColor: typePlan === 'anual' ? '#00d3ff' : 'transparent'
                    }}
                    onClick={() => setTypePlan('anual')}
                >
                    Anual
                </div>
            </div>
            <SimpleGrid
                w="90%" columns={{ base: 1, md: 3 }} spacing={{ base: 4, lg: 10 }} py={10}
            >
                {allPlans.map((v) => {
                    return (
                        <PriceWrapper key={v.id}>
                            <Box py={4} px={12}>
                                {v.idsPlans[typePlan === 'mensal' ? 0 : 1] === user?.plan?.stripe_plan ? (
                                    <Stack display="flex" alignItems="flex-end">
                                        <Tag
                                            bg={'main.500'}
                                            color="white"
                                            cursor="pointer"
                                            fontSize="md"
                                        >
                                            Plano atual
                                        </Tag>
                                    </Stack>
                                ) : null}
                                {v.idsPlans[typePlan === 'mensal' ? 0 : 1] !== user?.plan?.stripe_plan && v.plan === 'advanced' ? (
                                    <Stack display="flex" alignItems="flex-end">
                                        <Tag
                                            bg={'main.500'}
                                            color="white"
                                            cursor="pointer"
                                            fontSize="md"
                                        >
                                            Mais Contratado
                                        </Tag>
                                    </Stack>
                                ) : null}
                                {!(v.idsPlans[typePlan === 'mensal' ? 0 : 1] === user?.plan?.stripe_plan) && !(v.idsPlans[typePlan === 'mensal' ? 0 : 1] !== user?.plan?.stripe_plan && v.plan === 'advanced' ) ? (
                                    <Stack display="flex" alignItems="flex-end">
                                        <Tag
                                            bg='transparent'
                                            color="white"
                                            cursor="pointer"
                                        >
                                            
                                        </Tag>
                                    </Stack>
                                ) : null}
                                <Text fontWeight="500" fontSize="lg" color={textColor}>
                                    {v.name}
                                </Text>
                                {typePlan === 'mensal' ? (
                                    <HStack>
                                        <Text color={textColor} fontSize="2xl" style={{ fontWeight: 700, letterSpacing: 1, fontFamily: 'Encode Sans Condensed' }}>
                                            {v.priceMonth}
                                        </Text>
                                        <Text color={textColor} fontSize="xs" style={{ paddingTop: 15, letterSpacing: 1, fontFamily: 'Encode Sans Condensed' }}>
                                            por mês
                                        </Text>
                                    </HStack>
                                ) : (
                                    <>
                                        <HStack sx={{ textDecoration: 'line-through', textDecorationColor: '#00d3ff' }}>
                                            <Text color={textColor} fontSize="lg" style={{ fontWeight: 700, letterSpacing: 1, fontFamily: 'Encode Sans Condensed' }}>
                                                {v.priceYearlyFull}
                                            </Text>
                                            <Text color={textColor} fontSize="x-small" style={{ letterSpacing: 1, fontFamily: 'Encode Sans Condensed' }}>
                                                por ano
                                            </Text>
                                        </HStack>
                                        <HStack>
                                            <Text color={textColor} fontSize="2xl" style={{ fontWeight: 700, letterSpacing: 1, fontFamily: 'Encode Sans Condensed' }}>
                                                {v.priceYearly}
                                            </Text>
                                            <Text color={textColor} fontSize="xs" style={{ paddingTop: 15, letterSpacing: 1, fontFamily: 'Encode Sans Condensed' }}>
                                                por ano
                                            </Text>
                                        </HStack>
                                    </>
                                )}
                            </Box>
                            {user?.plan?.account_platform && (user?.plan?.stripe_expired_at ? (v.plan === user?.plan?.plan && (typePlan === 'mensal' ? 'month' : 'year') === user?.plan?.period) : true) ? (
                              <Stack>
                                  <Button
                                      w="sm"
                                      maxW="190px"
                                      minH="50px"
                                      bg={['CANCELAR PLANO', 'CANCELADO'].includes(renderLabel(v)) ? 'red.400' : 'main.700'}
                                      color={['CANCELAR PLANO', 'CANCELADO'].includes(renderLabel(v)) ? 'white' : '#060A14'}
                                      borderRadius="5px"
                                      style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 1, fontWeight: 600, fontSize: 14 }}
                                      isLoading={loading}
                                      onClick={async () => {
                                          setPlanSelect(v);
                                          setModalConfirmPlan(true);
                                      }}
                                      disabled={user?.plan?.stripe_cancel_at}
                                      alignSelf="center"
                                  >
                                      {renderLabel(v)}
                                  </Button>
                              </Stack>
                            ) : null}
                            <VStack
                                bg="transparent"
                                py={8}
                                minHeight="400px"
                                justifyContent="space-between"
                            >
                                <List spacing={3} textAlign="start" px={12}>
                                    {allDescriptions.map((e, i) => {
                                        if (i === 5 && typePlan === 'mensal') {
                                            return null;
                                        }
                                        return (
                                            <Stack key={e} direction={{ md: 'row', base: 'row' }} spacing={2}>
                                                {v.description.includes(i) ? (
                                                    <Icon as={MdCheck} fontSize="2xl" color='#00d3ff' mt={1} />
                                                ) : (
                                                    <Icon as={MdClose} fontSize="2xl" color="red" mt={1} />
                                                )}
                                                <Text style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 1 }} color={textColor} fontSize={'lg'}>{i === 1 ? e.replace('x', v.pecas) : (i === 14 ? e.replace('x', `${v.day} dia${v.day > 1 ? 's' : ''} ${v.day > 1 ? 'úteis' : 'útil'}`) : e)}</Text>
                                            </Stack>
                                        );
                                    })}
                                </List>
                                <Box w="80%" pt={7} justifyContent="center" display="flex" flexDirection="column" alignItems="center">
                                    {user?.plan?.account_platform && (user?.plan?.stripe_expired_at ? (v.plan === user?.plan?.plan && (typePlan === 'mensal' ? 'month' : 'year') === user?.plan?.period) : true) ? (
                                      <Button
                                          w="sm"
                                          maxW="190px"
                                          minH="50px"
                                          bg={['CANCELAR PLANO', 'CANCELADO'].includes(renderLabel(v)) ? 'red.400' : 'main.700'}
                                          color={['CANCELAR PLANO', 'CANCELADO'].includes(renderLabel(v)) ? 'white' : '#060A14'}
                                          borderRadius="5px"
                                          style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 1, fontWeight: 600, fontSize: 14 }}
                                          isLoading={loading}
                                          onClick={async () => {
                                              setPlanSelect(v);
                                              setModalConfirmPlan(true);
                                          }}
                                          disabled={user?.plan?.stripe_cancel_at}
                                      >
                                          {renderLabel(v)}
                                      </Button>
                                    ) : null}
                                    {user?.plan?.stripe_cancel_at && !user?.plan?.stripe_next_plan && renderLabel(v) === 'CANCELADO' ? (
                                        <Text mt={4} style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 1 }} color={'black'} fontSize={'lg'}>
                                            {`Expira em: ${lightFormat(user?.plan?.stripe_cancel_at, 'dd/MM/yyyy HH:mm')}`}
                                        </Text>
                                    ) : null}
                                    {user?.plan?.stripe_cancel_at && user?.plan?.stripe_next_plan && v.idsPlans[typePlan === 'mensal' ? 0 : 1] === user?.plan?.stripe_next_plan ? (
                                        <Text mt={4} style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 1 }} color={'black'} fontSize={'lg'}>
                                            {`Seu novo plano entrará em vigor no dia: ${lightFormat(user?.plan?.stripe_cancel_at, 'dd/MM/yyyy HH:mm')}`}
                                        </Text>
                                    ) : null}
                                </Box>
                            </VStack>
                        </PriceWrapper>
                    );
                })}
            </SimpleGrid>
            <Modal isOpen={modalConfirmPlan} onClose={onCloseModalPlan} isCentered scrollBehavior="inside" size="md">
                <ModalOverlay />
                <ModalContent style={{ fontFamily: 'Encode Sans Condensed' }}>
                    <ModalHeader fontSize="2xl">
                        Tem certeza que deseja continuar?
                        <Text color='secondaryGray.700' fontSize='sm' fontWeight='400'>Clique no botão abaixo para continuar</Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody display="flex" flexDirection="column">
                        {!planSelect ? null : (
                            <Button
                                w="sm"
                                maxW="190px"
                                minH="50px"
                                alignSelf="flex-end"
                                bg={['CANCELAR PLANO', 'CANCELADO'].includes(renderLabel(planSelect)) ? 'red.400' : 'main.700'}
                                color={['CANCELAR PLANO', 'CANCELADO'].includes(renderLabel(planSelect)) ? 'white' : '#060A14'}
                                borderRadius="5px"
                                style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 1, fontWeight: 600, fontSize: 14 }}
                                isLoading={loading}
                                onClick={async () => {
                                    const label = renderLabel(planSelect);
                                    if (label !== 'QUERO ASSINAR') {
                                        setLoading(true);
                                        const stripePlan = label === 'CANCELAR PLANO' ? null : planSelect.idsPlans[typePlan === 'mensal' ? 0 : 1];
                                        const responsePlan = await CancelPlan(
                                            stripePlan,
                                            label === 'CANCELAR PLANO' ? null : (label === 'FAZER UPGRADE' ? 'upgrade' : 'downgrade'),
                                            user?.user?.id,
                                            user?.token
                                        );
                                        onCloseModalPlan();
                                        if (responsePlan?.status === 201 || responsePlan?.status === 200) {
                                            await auth.updateDataUser({ ...user, plan: responsePlan?.result?.plan ? {
                                              ...responsePlan?.result?.plan,
                                              stripe_plan: stripePlan,
                                              stripe_cancel_at: responsePlan?.result?.stripe_cancel_at || null,
                                              stripe_next_plan: responsePlan?.result?.stripe_next_plan || null,
                                          } : user.plan});
                                            setLoading(false);
                                            return toast(label === 'CANCELAR PLANO' ? 'Assinatura cancelada com sucesso' : 'Assinatura atualizada com sucesso', {
                                                type: 'success'
                                            });
                                        }
                                        setLoading(false);
                                        return toast(`Entre em contato com o suporte. Ocorreu um erro ao cancelar a assinatura: ${JSON.stringify(responsePlan.message)}`, {
                                            type: 'error'
                                        });
                                    } else {
                                      setLoading(true);
                                      const stripePlan = planSelect.idsPlans[typePlan === 'mensal' ? 0 : 1];
                                      const responsePlan = await SignPlan(
                                          stripePlan,
                                          user?.user?.id,
                                          user?.token
                                      );
                                      onCloseModalPlan();
                                      if (responsePlan?.status === 201 || responsePlan?.status === 200) {
                                          await auth.updateDataUser({ ...user, plan: responsePlan?.result?.plan ? {
                                              ...responsePlan?.result?.plan,
                                              stripe_plan: stripePlan,
                                          } : user.plan});
                                          setLoading(false);
                                          return toast('Assinatura efetuada com sucesso', {
                                              type: 'success'
                                          });
                                      }
                                      setLoading(false);
                                      return toast(`Entre em contato com o suporte. Ocorreu um erro ao efetuar a assinatura: ${JSON.stringify(responsePlan.message)}`, {
                                          type: 'error'
                                      });
                                    }
                                }}
                            >
                                {renderLabel(planSelect)}
                            </Button>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
            <ToastContainer />
        </Box>
    )
}
