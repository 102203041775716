import { api } from './api';

export async function ListPetition(idUser, type, idPeticao, searchTxt, page, token) {
    try {
        let baseURL = '/peticoes?limit=10';
        if (type) {
            baseURL = `${baseURL}&type=${type}`
        }
        if (idPeticao) {
            baseURL = `${baseURL}&idPeticao=${idPeticao}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (idUser) {
            baseURL = `${baseURL}&idUser=${idUser}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function GetHtmlForPdf(pdf, token) {
    try {
        const response = await api.patch('/peticoes/pdfToText', { pdf }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreatePetition(idUser, title, html, type, token) {
    try {
        const response = await api.post('/peticoes', { idUser, title, html, type }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditPetition(title, html, type, idPeticao, token) {
    try {
        const response = await api.put(`/peticoes?idPeticao=${idPeticao}`, { title, html, type }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeletePetition(idPeticao, token) {
    try {
        const response = await api.delete(`/peticoes?idPeticao=${idPeticao}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
