import axios from "axios";
import { api } from "./api";

export async function CreateClientSecret(amount) {
    try {
        const response = await axios.post('https://api.stripe.com/v1/payment_intents', {
            amount,
            currency: 'brl',
            'automatic_payment_methods[enabled]': true,
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer 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',
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function SignPlan(stripePlan, idUser, token) {
    try {
        const response = await api.patch(`/users/subscription?idUser=${idUser}`, {
          stripePlan,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CancelPlan(nextPlan, nextType, idUser, token) {
    try {
        let baseURL = `/users/subscription?idUser=${idUser}`;
        if (nextPlan) {
            baseURL = `${baseURL}&nextPlan=${nextPlan}`;
        }
        if (nextType) {
            baseURL = `${baseURL}&nextType=${nextType}`;
        }
        const response = await api.delete(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
