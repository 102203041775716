import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdLock,
  MdWorkspacePremium,
  MdEmail,
} from "react-icons/md";
import {
  FaWhatsappSquare,
  FaUserFriends,
} from "react-icons/fa";
import {
    GrDocumentConfig,
} from "react-icons/gr";

// Admin Imports
import Petition from "views/admin/default";
import Profile from "views/admin/profile";
import Plans from "views/admin/plans";
import Customers from "views/admin/customers";
import Pecas from "views/admin/pecas";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signUp";
import forgotPasswordCentered from "views/auth/forgotPassword";
import { SiGoogledocs } from "react-icons/si";

export const formatRoutes = () => {
    const isAuthenticated = window.localStorage.getItem('@advtech_authenticated');

    const routesAuth = [
        {
            name: "Sign In",
            layout: "/auth",
            path: "/sign-in",
            icon: <Icon as={MdLock} fontSize="2xl" color='inherit' />,
            component: SignInCentered,
            hidden: true,
        },
        {
            name: "Sign Up",
            layout: "/auth",
            path: "/sign-up",
            icon: <Icon as={MdLock} fontSize="2xl" color='inherit' />,
            component: SignUpCentered,
            hidden: true,
        },
        {
            name: "Recover Password",
            layout: "/auth",
            path: "/forgot-password",
            icon: <Icon as={MdLock} fontSize="2xl" color='inherit' />,
            component: forgotPasswordCentered,
            hidden: true,
        },
    ];

    const routesAdm = [
        {
          name: "Meus documentos",
          layout: "/admin",
          path: "/default",
          icon: <Icon as={SiGoogledocs} fontSize="2xl" color='inherit' />,
          component: Petition,
        },
        {
          name: "Gerenciar planos",
          layout: "/admin",
          icon: <Icon as={MdWorkspacePremium} fontSize="2xl" color='inherit' />,
          path: "/plans",
          component: Plans,
        },
        {
          name: "Meu perfil",
          layout: "/admin",
          icon: <Icon as={MdPerson} fontSize="2xl" color='inherit' />,
          path: "/profile",
          component: Profile,
        },
        {
          name: "Cadastros",
          layout: "/admin",
          icon: <Icon as={FaUserFriends} fontSize="2xl" color='inherit' />,
          path: "/registrations",
          component: Customers,
        },
        {
          name: "Converse com o PDF",
          layout: "/admin",
          icon: <Icon as={GrDocumentConfig} fontSize="2xl" color='inherit' />,
          path: "/pecas",
          component: Pecas,
        },
        {
          name: "Suporte via e-mail",
          layout: "/admin",
          icon: <Icon as={MdEmail} fontSize="2xl" color='inherit' />,
          path: "/linkRedirect",
          component: Profile,
          linkRedirect: '',
        },
        {
          name: "Suporte via whatsapp",
          layout: "/admin",
          icon: <Icon as={FaWhatsappSquare} fontSize="2xl" color='inherit' />,
          path: "/linkRedirect",
          component: Profile,
          linkRedirect: `https://api.whatsapp.com/send?phone=11918633880&text=Sou Cliente Premium Advtech, meu nome é ${JSON.parse(isAuthenticated)?.user?.name || ''}, estou precisando de suporte.`,
        },
    ];

    if (isAuthenticated) {
        return [
            ...routesAuth,
            ...routesAdm,
        ];
    }
    
    return routesAuth;
}
