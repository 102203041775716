/* eslint-disable jsx-a11y/iframe-has-title */
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import * as Yup from 'yup';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { lightFormat } from "date-fns";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { Field, Form, Formik } from "formik";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
// import Recurso from "./Recurso";
import { EditCustomer } from "services/customers";
import { CreateCustomer } from "services/customers";
import { maskCPF } from "utils/masks";
import { DeleteCustomer } from "services/customers";
import { MaskRG } from "utils/masks";
export default function ColumnsTable(props) {
    const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt } = props;

    const { user } = useAuthContext();
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const tableInstance = useTable(
        {
        columns,
        data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 5;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const brandStars = useColorModeValue("main.500", "main.400");

    const [isOpen, setIsOpen] = useState(false);
    const [infoRow, setInfoRow] = useState(null);

    const onClose = () => {
        setIsOpen(false);
        setInfoRow(null);
    };

    const onClickEdit = (item) => {
        setIsOpen(true);
        setInfoRow(item);
    };

    const onClickDelete = async (item) => {
        await DeleteCustomer(item.id, user?.token);
        getList();
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Preencha o nome'),
        nacionalidade: Yup.string().notRequired(),
        estadoCivil: Yup.string().notRequired(),
        profissao: Yup.string().notRequired(),
        rg: Yup.string().notRequired(),
        orgaoExpedidor: Yup.string().notRequired(),
        cpf: Yup.string().notRequired(),
        addressEletronic: Yup.string().notRequired(),
        address: Yup.string().notRequired(),
        type: Yup.string().required('Preencha o tipo'),
    });

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex justifyContent="space-between" padding="0px 20px 0px 20px" mb="20px">
            <SearchBar
                placeholder={`Buscar cadastros`}
                value={searchTxt}
                setValue={setSearchTxt}
            />
            <Button onClick={() => setIsOpen(true)} w='200px' h='44px' mb='10px' variant="brand" alignSelf="flex-end" mr="10px" fontSize="lg">
                Criar novo cadastro
            </Button>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent style={{ fontFamily: 'Encode Sans Condensed' }}>
                <ModalHeader fontSize="2xl">
                    Criar cadastro
                    <Text color='secondaryGray.700' fontSize='sm' fontWeight='400'>{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} um cadastro`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column">
                    <Flex
                    w="100%"
                    pt={{ base: '70px', md: '0px' }}
                    direction="column"
                    position="relative"
                    >
                        <Formik
                            initialValues={{
                                name: infoRow?.name || '',
                                nacionalidade: infoRow?.nacionalidade || '',
                                estadoCivil: infoRow?.estado_civil || '',
                                profissao: infoRow?.profissao || '',
                                rg: infoRow?.rg || '',
                                orgaoExpedidor: infoRow?.orgao_expedidor || '',
                                cpf: infoRow?.cpf || '',
                                addressEletronic: infoRow?.address_eletronic || '',
                                address: infoRow?.address || '',
                                type: infoRow?.type || '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={async (values, helpers) => {
                                try {
                                    const response = infoRow?.id ? await EditCustomer(
                                        user?.user?.id,
                                        values.name,
                                        values.nacionalidade,
                                        values.estadoCivil,
                                        values.profissao,
                                        values.rg,
                                        values.orgaoExpedidor,
                                        values.cpf,
                                        values.addressEletronic,
                                        values.address,
                                        values.type,
                                        infoRow?.id,
                                        user?.token,
                                    ) : await CreateCustomer(
                                        user?.user?.id,
                                        values.name,
                                        values.nacionalidade,
                                        values.estadoCivil,
                                        values.profissao,
                                        values.rg,
                                        values.orgaoExpedidor,
                                        values.cpf,
                                        values.addressEletronic,
                                        values.address,
                                        values.type,
                                        user?.token,
                                    );
                                    if (response?.status === 200 || response?.status === 201) {
                                        helpers.setSubmitting(false);
                                        getList();
                                        onClose();
                                    } else {
                                        helpers.setStatus({ success: false });
                                        helpers.setErrors({ submit: response?.message });
                                        helpers.setSubmitting(false);
                                    }
                                } catch (err) {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: err.message });
                                    helpers.setSubmitting(false);
                                }
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Grid mb="20px" alignItems="center" templateColumns='repeat(2, 1fr)' gap='16px'>
                                        <Field name='name'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.name && form.touched.name}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='md'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        Nome<Text color={brandStars}>*</Text>
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='md'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digite o nome'
                                                        fontWeight='500'
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='nacionalidade'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.nacionalidade && form.touched.nacionalidade}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='md'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        Nacionalidade
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='md'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digite a nacionalidade'
                                                        fontWeight='500'
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.nacionalidade}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='estadoCivil'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.estadoCivil && form.touched.estadoCivil}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='md'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        Estado civil
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='md'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digite o estado civil'
                                                        fontWeight='500'
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.estadoCivil}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='profissao'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.profissao && form.touched.profissao}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='md'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        Profissão
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='md'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digite a profissão'
                                                        fontWeight='500'
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.profissao}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='rg'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.rg && form.touched.rg}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='md'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        RG
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='md'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digite a profissão'
                                                        fontWeight='500'
                                                        size='lg'
                                                        onChange={(txt) => {
                                                            if (txt.target.value.length <= 12) {
                                                                const formatRG = MaskRG(txt.target.value);
                                                                props.handleChange({
                                                                    target: {
                                                                        name: "rg",
                                                                        value: formatRG,
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <FormErrorMessage>{form.errors.rg}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='orgaoExpedidor'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.orgaoExpedidor && form.touched.orgaoExpedidor}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='md'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        Orgão expedidor
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='md'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digite o orgão expedidor'
                                                        fontWeight='500'
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.orgaoExpedidor}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='cpf'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.cpf && form.touched.cpf}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='md'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        CPF
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='md'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digite a CPF'
                                                        fontWeight='500'
                                                        size='lg'
                                                        onChange={(txt) => {
                                                            if (txt.target.value?.length <= 14) {
                                                                const formatIdentifier = maskCPF(txt.target.value);
                                                                props.handleChange({
                                                                    target: {
                                                                        name: "cpf",
                                                                        value: formatIdentifier,
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <FormErrorMessage>{form.errors.cpf}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='addressEletronic'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.addressEletronic && form.touched.addressEletronic}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='md'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        Endereço eletrônico
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='md'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digite o endereço eletrônico'
                                                        fontWeight='500'
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.addressEletronic}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='address'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.address && form.touched.address}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='md'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        Endereço
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='md'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digite o endereço'
                                                        fontWeight='500'
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='type'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.type && form.touched.type}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='md'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        Tipo<Text color={brandStars}>*</Text>
                                                    </FormLabel>
                                                    <Select
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='md'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Selecione o tipo'
                                                        fontWeight='500'
                                                        size='lg'
                                                    >
                                                        <option value='author'>Autor</option>
                                                        <option value='reu'>Réu</option>
                                                    </Select>
                                                    <FormErrorMessage>{form.errors.type}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Flex justifyContent="flex-end">
                                        <Button
                                            fontSize='md'
                                            variant='brand'
                                            fontWeight='500'
                                            w='200px'
                                            h='50'
                                            isLoading={props.isSubmitting}
                                            type="submit"
                                        >
                                            {infoRow?.id ? 'Salvar dados' : 'Criar dados'}
                                        </Button>
                                    </Flex>
                                    {props.errors.submit && (
                                        <Text
                                            color={props.status.success ? 'green.500' : 'red.500'}
                                            fontSize='md'
                                            fontWeight='400'
                                            marginTop="24px"
                                            mb="24px"
                                        >
                                            {props.errors.submit}
                                        </Text>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
        <Table {...getTableProps()} variant='simple' color='gray.500' mb="100px">
            <Thead>
            {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                    <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                        justify='space-between'
                        align='center'
                        style={{ fontFamily: 'Encode Sans Condensed' }}
                        fontSize={{ sm: "12px", lg: "14px" }}
                        color='gray.400'>
                        {column.render("Header")}
                    </Flex>
                    </Th>
                ))}
                </Tr>
            ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
                prepareRow(row);
                return (
                <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "AÇÕES") {
                        return (
                            <Flex align='center' pl="30px" pt="5px">
                                <Menu row={cell.row.original} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
                            </Flex>
                        )
                    } else if (cell.column.Header === "SITUAÇÃO") {
                        data = (
                        <Text color={cell.value ? 'red.500' : 'green.500'} fontSize='md' fontWeight='700'>
                            {cell.value ? 'Inativo' : 'Ativo'}
                        </Text>
                        );
                    } else if (cell.column.Header === "CRIADO EM") {
                        data = (
                        <Text color={textColor} fontSize='md' fontWeight='700'>
                            {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                        </Text>
                        );
                    } else if (cell.column.Header === "NOME") {
                        data = (
                        <Text color={textColor} fontSize='md' fontWeight='700'>
                            {cell.value}
                        </Text>
                        );
                    } else if (cell.column.Header === "CPF") {
                        data = (
                        <Text color={textColor} fontSize='md' fontWeight='700'>
                            {cell.value}
                        </Text>
                        );
                    }
                    return (
                        <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize="md"
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                        </Td>
                    );
                    })}
                </Tr>
                );
            })}
            </Tbody>
        </Table>
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
        />
    </Card>
  );
}
