/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
//   Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
// import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
// import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Signup } from "services/auth";
import { useAuth } from 'hooks/use-auth';
import { maskPhone } from "utils/masks";
import { CreateClientSecret } from 'services/stripe';
import { Elements} from '@stripe/react-stripe-js';
import { AppElement } from './appElement';
import { loadStripe } from "@stripe/stripe-js";
import { maskCPForCNPJ } from "utils/masks";

function SignUp() {
    const auth = useAuth();
    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("main.500", "white");
    const brandStars = useColorModeValue("main.500", "main.400");
    // const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    // const googleText = useColorModeValue("navy.700", "white");
    // const googleHover = useColorModeValue(
    //     { bg: "gray.200" },
    //     { bg: "whiteAlpha.300" }
    // );
    // const googleActive = useColorModeValue(
    //     { bg: "secondaryGray.300" },
    //     { bg: "whiteAlpha.200" }
    // );
    const getIdAffiliate = window.sessionStorage.getItem('@advtech_idAffiliate');
    const getIdPlan = window.sessionStorage.getItem('@advtech_idPlan');
    const stripePromise = loadStripe('pk_live_51OoBugLfMobjDc5NhvC9Paja6zM2tQf9sAewRpyMjcelwaczVoRmjHkufERdHQGuMSci3Z9f3gUWDLpUipgXOkhA004s3L8eIC');
    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [idAffiliate, setIdAffiliate] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const [idPlan, setIdPlan] = useState(getIdPlan || '$2a$10$O9vdb1W/KTF8cfFFKWGIRuPxw4f1LYCoXC9XtTOBydYIb6cULWcU6');
    const [paymentMethod, setPaymentMethod] = useState('');

    const handleClick = () => setShow(!show);
    const handleClickConfirm = () => setShowConfirm(!showConfirm);

    const validationSchema = Yup.object().shape({
        stripePlan: Yup.string().required('O plano é obrigatório'),
        name: Yup.string().required('O nome é obrigatório'),
        document: Yup.string().required('O documento é obrigatório'),
        email: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório'),
        whatsapp: Yup.string().required('O whatsapp é obrigatório'),
        password: Yup.string().required('A senha é obrigatória'),
        confirmPassword: Yup.string().required('A confirmação de senha é obrigatória'),
    });

    const listPlan = [
      {
        id: '$2a$10$O9vdb1W/KTF8cfFFKWGIRuPxw4f1LYCoXC9XtTOBydYIb6cULWcU6',
        label: 'Plano Básico - Mensal',
        price: 9700,
      },
      {
        id: '$2a$10$yPLldZXuYe0D7kCBxn87YObWewbl8wa5IttLxCflyajaw0MnMmvhO',
        label: 'Plano Básico - Anual',
        price: 104760,
      },
      {
        id: '$2a$10$teaJsmrfdE1q1m/YbmfodOVfJG4nS2vDnpls8Hqt9YBtc/1/jC/pq',
        label: 'Plano Advanced - Mensal',
        price: 14700,
      },
      {
        id: '$2a$10$i5okQvA9axO6qhCGDJtWnOm2hgmujG0n905g69CYV5T1owyOC/D06',
        label: 'Plano Advanced - Anual',
        price: 158760,
      },
      {
        id: '$2a$10$vvqc3ude8updl0oGrgoh8e3kEwM3lhhd8GAtzkdpvYoI5ovwKr2K2',
        label: 'Plano Premium - Mensal',
        price: 24700,
      },
      {
        id: '$2a$10$Oe3xb1zXPC/xxgFpxezTfucxTFX/yilCDaloYRs8QxIe81ocHVaHu',
        label: 'Plano Premium - Anual',
        price: 266760,
      },
    ];

    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    const generatorClientSecret = async () => {
      const pricePlan = listPlan.find((v) => v.id === idPlan).price;
      const response = await CreateClientSecret(pricePlan);
      setClientSecret(response.client_secret);
    };

    useEffect(() => {
        if (getIdAffiliate) {
            setIdAffiliate(getIdAffiliate);
            window.sessionStorage.removeItem('@advtech_idAffiliate');
        }
        if (getIdPlan) {
            window.sessionStorage.removeItem('@advtech_idPlan');
        }
    }, []);

    useEffect(() => {
      if (idPlan) {
        generatorClientSecret();
      }
    }, [idPlan]);

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection='column'
                style={{ fontFamily: 'Encode Sans Condensed' }}
            >
                <Box me='auto'>
                    <Heading style={{ fontFamily: 'Encode Sans Condensed' }} color={textColor} fontSize='5xl' mb='10px'>
                        Cadastro
                    </Heading>
                    <Text
                        mb='36px'
                        ms='4px'
                        color={textColorSecondary}
                        fontWeight='400'
                        fontSize='lg'
                    >
                        Preencha as informações abaixo para se cadastrar.
                    </Text>
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "700px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}
                >
                    <Formik
                        initialValues={{ stripePlan: getIdPlan || '$2a$10$O9vdb1W/KTF8cfFFKWGIRuPxw4f1LYCoXC9XtTOBydYIb6cULWcU6', name: '', document: '', email: '', whatsapp: '', password: '', confirmPassword: '' }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            if (!paymentMethod) {
                              helpers.setStatus({ success: false });
                              helpers.setErrors({ submit: 'Salve os dados do cartão antes de prosseguir.' });
                              helpers.setSubmitting(false);
                              return null;
                            }
                            if (!isChecked) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'Aceite os termos de uso e política de privacidade antes de prosseguir' });
                                helpers.setSubmitting(false);
                                return null;
                            }
                            if (values.password !== values.confirmPassword) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'As senhas precisam ser iguais.' });
                                helpers.setSubmitting(false);
                                return null;
                            }
                            try {
                                let body = {
                                    paymentMethod,
                                    stripePlan: values.stripePlan,
                                    name: values.name,
                                    document: values.document,
                                    email: values.email,
                                    whatsapp: values.whatsapp,
                                    password: values.password,
                                };
                                if (idAffiliate && idAffiliate !== 'noIdAffiliate') {
                                    body = {
                                        ...body,
                                        hashId: idAffiliate,
                                    }
                                }
                                const response = await Signup(body);
                                if (response?.status === 200 || response?.status === 201) {
                                    helpers.setSubmitting(false);
                                    await auth.updateDataUser(response.result, 'refresh');
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
                                    <Field name='name'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.name && form.touched.name}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='md'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Nome<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='md'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu nome'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='document'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.document && form.touched.document}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='md'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Documento (CPF/CNPJ)<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='md'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu documento'
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                      const applyMask = maskCPForCNPJ(txt.target.value);
                                                      props.setFieldValue('document', applyMask);
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.document}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='email'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.email && form.touched.email}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    E-mail<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    type='email'
                                                    placeholder='Seu e-mail'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='whatsapp'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.whatsapp && form.touched.whatsapp}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='md'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Whatsapp<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='md'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Seu whatsapp'
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                      const formatTelephone = maskPhone(txt.target.value);
                                                      props.setFieldValue('whatsapp', formatTelephone);
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.whatsapp}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='password'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.password && form.touched.password}>
                                                <FormLabel
                                                    ms='4px'
                                                    fontSize='md'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    display='flex'>
                                                    Senha<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <InputGroup size='md'>
                                                    <Input
                                                        {...field}
                                                        fontSize='md'
                                                        placeholder='Sua senha'
                                                        size='lg'
                                                        type={show ? "text" : "password"}
                                                        variant='auth'
                                                    />
                                                    <InputRightElement display='flex' alignItems='center' mt='4px'>
                                                        <Icon
                                                            color={textColorSecondary}
                                                            _hover={{ cursor: "pointer" }}
                                                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                            onClick={handleClick}
                                                        />
                                                    </InputRightElement>
                                                </InputGroup>
                                                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='confirmPassword'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.confirmPassword && form.touched.confirmPassword}>
                                                <FormLabel
                                                    ms='4px'
                                                    fontSize='md'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    display='flex'>
                                                    Confirmar senha<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <InputGroup size='md'>
                                                    <Input
                                                        {...field}
                                                        fontSize='md'
                                                        placeholder='Confirme sua senha'
                                                        size='lg'
                                                        type={showConfirm ? "text" : "password"}
                                                        variant='auth'
                                                    />
                                                    <InputRightElement display='flex' alignItems='center' mt='4px'>
                                                        <Icon
                                                            color={textColorSecondary}
                                                            _hover={{ cursor: "pointer" }}
                                                            as={showConfirm ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                            onClick={handleClickConfirm}
                                                        />
                                                    </InputRightElement>
                                                </InputGroup>
                                                <FormErrorMessage>{form.errors.confirmPassword}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='stripePlan'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.stripePlan && form.touched.stripePlan}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='md'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Plano<Text color={brandStars}>*</Text>
                                                </FormLabel>
                                                <Select
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='md'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Selecione o tipo'
                                                    fontWeight='500'
                                                    size='lg'
                                                    onChange={(txt) => {
                                                        props.handleChange({
                                                            target: {
                                                                name: "stripePlan",
                                                                value: txt.target.value,
                                                            }
                                                        });
                                                        setIdPlan(txt.target.value);
                                                    }}
                                                >
                                                    {listPlan.map((v) => (
                                                      <option value={v.id}>{v.label}</option>
                                                    ))}
                                                </Select>
                                                <FormErrorMessage>{form.errors.stripePlan}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    {clientSecret ? (
                                      <Elements stripe={stripePromise} options={{ clientSecret }}>
                                        <AppElement setPaymentMethod={setPaymentMethod} />
                                      </Elements>
                                    ) : null}
                                </Grid>
                                <Text
                                    mt="20px"
                                    mb='20px'
                                    color="red.500"
                                    fontWeight='400'
                                    fontSize='lg'
                                >
                                    {`*Teste grátis por sete dias. O valor da assinatura só será cobrado a partir do 8º dia.`}
                                </Text>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='md'
                                        fontWeight='400'
                                        marginTop="24px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Flex
                                    justifyContent='center'
                                    alignItems='center'
                                    maxW='100%'
                                    mt='0px'
                                    marginTop="24px"
                                    display="flex"
                                    alignSelf="flex-start"
                                >
                                    <Checkbox
                                        colorScheme='brandScheme'
                                        me='10px'
                                        isChecked={isChecked}
                                        onChange={handleChange}
                                    />
                                    <Text color={textColorDetails} fontWeight='400' fontSize='md'>
                                        Eu concordo com os
                                        <a target="_blank" rel="noopener noreferrer" href='https://advtech.sfo3.digitaloceanspaces.com/documentos/termos.pdf'>
                                            <Text
                                                color={textColorBrand}
                                                as='span'
                                                ms='5px'
                                                mr="5px"
                                                fontWeight='500'
                                            >
                                                Termos de uso
                                            </Text>
                                        </a>
                                        e a
                                        <a target="_blank" rel="noopener noreferrer" href='https://advtech.sfo3.digitaloceanspaces.com/documentos/privacidade.pdf'>
                                            <Text
                                                color={textColorBrand}
                                                as='span'
                                                ms='5px'
                                                fontWeight='500'
                                            >
                                                Política de Privacidade
                                            </Text>
                                        </a>
                                    </Text>
                                </Flex>
                                <Button
                                    fontSize='md'
                                    variant='brand'
                                    fontWeight='500'
                                    w='50%'
                                    h='50'
                                    mt='24px'
                                    mb="24px"
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                >
                                    Cadastrar-se
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <Flex
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='start'
                        maxW='100%'
                        mt='0px'
                    >
                        <Text color={textColorDetails} fontWeight='400' fontSize='md'>
                            Já tem uma conta?
                            <NavLink to='/auth/sign-in'>
                                <Text
                                    color={textColorBrand}
                                    as='span'
                                    ms='5px'
                                    fontWeight='500'
                                >
                                    Acesse aqui
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default SignUp;
