import { api } from './api';

export async function ListCustomers(idUser, idCustomer, type, searchTxt, page, token) {
    try {
        let baseURL = '/customers?limit=10';
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (idCustomer) {
            baseURL = `${baseURL}&idCustomer=${idCustomer}`
        }
        if (idUser) {
            baseURL = `${baseURL}&idUser=${idUser}`
        }
        if (type) {
            baseURL = `${baseURL}&type=${type}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateCustomer(idUser, name, nacionalidade, estadoCivil, profissao, rg, orgaoExpedidor, cpf, addressEletronic, address, type, token) {
    try {
        const response = await api.post('/customers', { idUser, name, nacionalidade, estadoCivil, profissao, rg, orgaoExpedidor, cpf, addressEletronic, address, type }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditCustomer(idUser, name, nacionalidade, estadoCivil, profissao, rg, orgaoExpedidor, cpf, addressEletronic, address, type, idCustomer, token) {
    try {
        const response = await api.put(`/customers?idCustomer=${idCustomer}`, { idUser, name, nacionalidade, estadoCivil, profissao, rg, orgaoExpedidor, cpf, addressEletronic, address, type }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteCustomer(idCustomer, token) {
    try {
        const response = await api.delete(`/customers?idCustomer=${idCustomer}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
