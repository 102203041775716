/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
import ColumnsTable from "./components/ColumnsTable";
import { useAuthContext } from "contexts/SidebarContext";
import { columnsDataColumns } from "./variables/columnsData";
import { ListCustomers } from "services/customers";

export default function Users() {
    const { user } = useAuthContext();

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getList();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [currentPage, searchTxt]);

    const getList = async () => {
        const response = await ListCustomers(user?.user?.id, null, null, searchTxt, currentPage, user?.token);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
    };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card
            direction='column'
            w='100%'
            px='0px'
            p="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
            style={{ fontFamily: 'Encode Sans Condensed' }}
        >
            <ColumnsTable
                columnsData={columnsDataColumns}
                tableData={list}
                getList={getList}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                searchTxt={searchTxt}
                setSearchTxt={setSearchTxt}
            />
        </Card>
    </Box>
  );
}
